import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import Modal from '~/components/Modal/Modal';
import Spinner from '~/components/Spinner/Spinner';
import { useToasts } from '~/context/ToastContext';
import ErrorIcon from '~/icons/ErrorIcon';
import formStyles from '~/scss/layout/form.module.scss';
import '~/scss/layout/select.scss';

import { useFetcher } from '@remix-run/react';

import styles from './ReportModal.module.scss';

interface ActionData {
  errors?: {
    reason?: string;
  };
  status: number;
}

const options = [
  { value: 'copyright', label: 'Copyright Infringement' },
  { value: 'offensive', label: 'Inappropriate or Offensive Content' },
  { value: 'misleading', label: 'Misleading or False Information' },
  { value: 'other', label: 'Other' },
];

interface Props {
  article_id: number;
  onClose: () => void;
}

const ReportModal: FC<Props> = ({ onClose, article_id }) => {
  const { createToast } = useToasts();

  const [showTextArea, setShowTextArea] = useState(false);
  const [selectedReason, setSelectedReason] = useState<string | null>(null);

  const reportedArticles = JSON.parse(
    localStorage.getItem('reportedArticles') || '[]',
  );
  const isArticleReported = reportedArticles.includes(article_id);

  useEffect(() => {
    setShowTextArea(selectedReason === 'other');
  }, [selectedReason]);

  const handleChange = (
    selectedOption: SingleValue<{ value: string; label: string }>,
  ) => {
    setSelectedReason(selectedOption?.value || null);
  };

  const fetcher = useFetcher<ActionData>();
  const isSubmitting = fetcher.state === 'submitting';
  const errors = fetcher.data?.errors;

  useEffect(() => {
    if (fetcher.data?.status === 200 && fetcher.state === 'idle') {
      if (!reportedArticles.includes(article_id)) {
        reportedArticles.push(article_id);
        localStorage.setItem(
          'reportedArticles',
          JSON.stringify(reportedArticles),
        );
        createToast('success', 'Your report has been submitted');
      }
      onClose();
    }
  }, [
    fetcher.data?.status,
    fetcher.state,
    article_id,
    reportedArticles,
    createToast,
    onClose,
  ]);

  return (
    <Modal>
      <div className={styles.reportModal}>
        <h3 className={styles.heading}>Report an image</h3>
        <fetcher.Form method='post' action='/image-report'>
          {isArticleReported ? (
            <>
              <p className={styles.description}>
                Sorry, it looks like you have already reported this image.
              </p>
              <button
                onClick={onClose}
                type='button'
                className={formStyles.button}
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <div className={styles.formLayout}>
                <div>
                  <label className={formStyles.label} htmlFor='reason'>
                    Choose a reason
                  </label>
                  <div
                    className={classNames('select-holder', {
                      error: !!errors?.reason,
                    })}
                  >
                    <Select
                      aria-live='off'
                      isSearchable={false}
                      options={options}
                      onChange={handleChange}
                      classNamePrefix='react-select'
                      name='reason'
                      id='reason'
                      value={options.find(
                        (option) => option.value === selectedReason,
                      )}
                    />
                  </div>
                  {errors?.reason && (
                    <div className={formStyles.errorMessage}>
                      <ErrorIcon />
                      {errors.reason}
                    </div>
                  )}
                </div>
                {showTextArea && (
                  <div>
                    <label className={formStyles.label} htmlFor='reason-text'>
                      Reason for reporting
                    </label>
                    <textarea
                      className={classNames(formStyles.textarea, {
                        [formStyles.error]: !!errors?.reason,
                      })}
                      id='reason-text'
                      name='reason-text'
                      placeholder='Add text'
                    />
                    {errors?.reason && (
                      <div className={formStyles.errorMessage}>
                        <ErrorIcon />
                        {errors.reason}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={formStyles.buttons}>
                <button
                  type='submit'
                  className={classNames(
                    formStyles.button,
                    formStyles.buttonRed,
                  )}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <Spinner /> : 'Report'}
                </button>
                <button
                  onClick={onClose}
                  type='button'
                  className={formStyles.button}
                >
                  Cancel
                </button>
              </div>
              <input hidden name='article_id' defaultValue={article_id} />
            </>
          )}
        </fetcher.Form>
      </div>
    </Modal>
  );
};

export default ReportModal;
